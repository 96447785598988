import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IntegrityPolicyPage = () => {
  return (
    <Layout>
      <SEO
        title="Integritetspolicy"
        description={`Här beskriver vi vilka personuppgifter vi samlar in och varför vi samlar in dem.`}
      />
      <div className="article-container">
        <h1 className="section-title">Integritetspolicy</h1>
        <h2>Vilka personuppgifter vi samlar in och varför vi samlar in dem</h2>
        <h3 className="section-title">Kontaktformulär</h3>
        <p>
          Om du lämnar ett meddelande genom kontaktformläret på vår webbplats så
          sparar vi ditt namn, din IP-adress samt din e-post-adress.
        </p>
        <h3 className="section-title">
          Inbäddad innehåll från andra webbplatser
        </h3>
        <p>
          Artiklar på denna webbplats kan innehålla inbäddat innehåll
          (exempelvis videoklipp, bilder, artiklar o.s.v.). Inbäddat innehåll
          från andra webbplatser beter sig precis på samma sätt som om besökaren
          har besökt den andra webbplatsen.
        </p>
        <p>
          Dessa webbplatser kan samla in uppgifter om dig, använda cookie-filer,
          bädda in ytterligare spårning från tredje part och övervaka din
          interaktion med sagda inbäddade innehåll, inklusive spårning av din
          interaktion med detta inbäddade innehåll om du har ett konto och är
          inloggad på webbplatsen i fråga.
        </p>
        <h3 className="section-title">Hur länge vi behåller era uppgifter</h3>
        <p>
          Om du lämnar ett meddelande genom kontaktformläret kommer meddelandet
          och dess metadata att sparas utan tidsgräns på vår mailserver.
        </p>
        <h3 className="section-title">
          Vilka rättigheter du har över dina data
        </h3>
        <p>
          Om du har ett konto eller har skrivit några kommentarer på denna
          webbplats kan du begära en exportfil med de personuppgifter vi har om
          dig, inklusive alla uppgifter du har gett oss. Du kan också begära att
          vi tar bort alla personuppgifter vi har om dig. Detta omfattar inte
          eventuella uppgifter som vi är tvungna att spara av administrativa,
          legala eller säkerhetsändamål.
        </p>
        <h3 className="section-title">Vart vi skickar dina upgifter</h3>
        <p>
          Meddelanden skickade genom ett kontaktformulär från besökare kan
          kontrolleras via en automatiserad tjänst för detektering av skräppost.
        </p>
      </div>
    </Layout>
  )
}

export default IntegrityPolicyPage
